import request from '@/utils/request'
import SERVER from './server'
import { thenResolve } from '@/utils/index'

const TodoServices = {
  // 待办列表
  async getTodoList(params) {
    return await request
      .post(`${SERVER.STATISTICS_SERVICE}/api/v1/todo/list`, params)
      .then(thenResolve)
  },
  async getTodayFinish(params) {
    return await request
      .post(
        `${SERVER.STATISTICS_SERVICE}/api/v1/statistics/today-finish`,
        params
      )
      .then(thenResolve)
  },
  // 我的待办任务列表
  async taskList(params) {
    return await request
      .post(`${SERVER.LEAD_SERVICE}/api/v1/task/list`, params)
      .then(thenResolve)
  },
  // 企微任务列表
  async qwTaskList(params) {
    return await request
      .post(`${SERVER.LEAD_SERVICE}/api/v1/task/qwList`, params)
      .then(thenResolve)
  },

  // 建群任务
  async chatGroupTaskList(params) {
    return await request
      .post(`${SERVER.LEAD_SERVICE}/api/v1/chatGroupTask/list`, params)
      .then(thenResolve)
  },
  // 更新建群任务
  async chatGroupTaskUpdate(params) {
    return await request
      .post(`${SERVER.LEAD_SERVICE}/api/v1/chatGroupTask/updateBatch`, params)
      .then(thenResolve)
  },
  // 批量更新群开场白任务
  async updateBatchChatWelcomeMsgTask(params) {
    return await request
      .post(
        `${SERVER.LEAD_SERVICE}/api/v1/chatGroupTask/updateBatchChatWelcomeMsgTask`,
        params
      )
      .then(thenResolve)
  },

  // 短信
  async inviteBatch(params) {
    return await request
      .post(`${SERVER.LEAD_SERVICE}/api/v1/chatGroupTask/inviteBatch`, params)
      .then(thenResolve)
  },
  // 更新任务前校验
  async openEnterpriseChatBeforeUpdate(params) {
    return await request
      .post(`${SERVER.LEAD_SERVICE}/api/v1/chatGroupTask/beforeUpdate`, params)
      .then(thenResolve)
  },
  // 企微待办统计
  async getQwTaskTotal(params = {}) {
    return await request
      .post(`${SERVER.LEAD_SERVICE}/api/v1/chatGroupTask/qwTaskTotal`, params)
      .then(thenResolve)
  },
  // 取消任务
  async cancelTask(params = {}) {
    return await request
      .post(`${SERVER.LEAD_SERVICE}/api/v1/chatGroupTask/cancel`, params)
      .then(thenResolve)
  },
  // 批量更新群成员
  async chatNewMember(params = {}) {
    return await request
      .post(`${SERVER.LEAD_SERVICE}/api/v1/chatGroupTask/chatNewMember`, params)
      .then(thenResolve)
  },
}

export default TodoServices
