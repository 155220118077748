<template>
  <div class="group-chats">
    <p v-if="taskCode == 'WECHAT_TO_LEAD'" class="total">{{ $t("以下微信客户还未创建或关联线索，需及时创建线索") }}</p>
    <p class="total">{{ $t("共") }} {{ totalCount }} {{ $t("条数据") }}</p>
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item }">
        <div class="item-wrap">
          <div style="display: flex; align-items: center;">
            <div style="flex: 1; overflow: hidden;">
              <template v-if="taskCode == 'CHAT_GROUP_TASK'">
                <div class="user-info mb-8">
                  <span>{{ item.chatName }}</span>
                </div>
                <div class="mb-8">
                  <span class="label">{{ $t("微信客户") }}：</span>
                  <span>
                    {{
                      item.userWeChatName || item.lead.userWeChatName || "--"
                    }}
                  </span>
                </div>
              </template>
              <template v-if="taskCode == 'WECHAT_TO_LEAD'">
                <div class="wechat-info mb-8">
                  <span class="label">{{ $t("微信客户") }}：</span>
                  <span>{{ item.customerName || "--" }} </span>
                  <qwIcon :externalUserIds="item.customerWechatId" class="icon"/>
                </div>
                <div class="mb-8">
                  <span class="label">{{ $t("销售顾问") }}：</span>
                  <span>{{ item.planExecutorName || "--" }} </span>
                </div>
              </template>
              <template v-else-if="taskCode == 'CHAT_WELCOME_MSG'">
                <div class="user-info mb-8">
                  <span>{{ item.chatGroup.chatName }}</span>
                </div>
                <div class="mb-8" style="margin-right: 40px;">
                  <span class="label">{{ $t("发送内容") }}：</span>
                  <span>{{ item.chatGroup.welcomeMsg || "--" }}</span>
                </div>
              </template>
              <template v-else-if="taskCode == 'LEAD_INVITE_ADD_WECHAT'">
                <div class="user-info mb-8">
                  <span class="van-ellipsis">{{ item.customerName }}</span>
                  <span
                    style="width: 1px;margin: 0 8px;height: 12px;background-color: #d9d9d9;"
                  >
                  </span>
                  <span>
                    {{
                      $store.getters.userInfo.type != 1
                        ? item.customerMobile.replace(
                            /^(1[3-9][0-9])\d{4}(\d{4}$)/,
                            "$1***$2"
                          )
                        : item.customerMobile
                    }}
                  </span>
                  <img
                    v-if="isShowCopyMobile || $store.getters.userInfo.type == 1"
                    :src="copyIcon"
                    style="margin-left: 8px;"
                    width="16px"
                    @click.stop="copy(item.customerMobile)"
                  />
                </div>
              </template>
              <template v-else-if="taskCode == 'CHAT_ADD_MEMBER'">
                <div class="user-info mb-8">
                  <span>{{ item.chatMember.chatName }}</span>
                </div>
                <div class="mb-8">
                  <span class="label">{{ $t("添加群成员") }}：</span>
                  <span v-if="item.chatMember">
                    {{
                      `${item.chatMember.empName} | ${
                        roleLogoName[item.chatMember.roleLogo]
                      }`
                    }}
                  </span>
                </div>
              </template>
              <div class="mb-8">
                <span class="label">{{ $t("任务生成时间") }}：</span>
                <span>
                  {{
                    item.createTime
                      ? $moment(item.createTime).format("YYYY-MM-DD HH:mm")
                      : "--"
                  }}
                </span>
              </div>
              <div v-if="taskCode != 'WECHAT_TO_LEAD'" class="mb-8">
                <span class="label">{{ $t("执行人") }}：</span>
                <span>
                  {{ item.planExecutorName || "--" }}
                </span>
              </div>
            </div>
            <div v-if="taskCode != 'WECHAT_TO_LEAD'" class="select-icon" @click.stop="onSelect(item)">
              <van-icon
                v-if="selection.includes(item.id)"
                name="checked"
                color="#EED484"
                size="16"
              />
              <van-icon v-else size="16" name="circle" color="#0D171A" />
            </div>
          </div>
          <div v-if="taskCode == 'WECHAT_TO_LEAD'" class="item-actions">
            <span @click="goCluePage(item)">
              {{ $t("关联已有线索") }}
            </span>
            <span @click="goCreateWechatCluePage(item)">
              {{ $t("创建新线索") }}
            </span>
          </div>
          <div v-if="taskCode == 'LEAD_INVITE_ADD_WECHAT'" class="item-actions">
            <span @click="inviteBatch([item.id])">
              {{ $t("短信加微") }}
            </span>
            <span @click="addC(item.customerMobile)">
              {{ $t("添加客户") }}
            </span>
          </div>
        </div>
      </template>
    </List>
    <div style="height:52px"></div>
    <div v-if="taskCode != 'WECHAT_TO_LEAD'" class="footer safe-area-inset-bottom">
      <div class="action-box">
        <div @click.stop="onSelectAll">
          <van-icon
            v-if="selection.length == currentTotal && currentTotal"
            name="checked"
            color="#EED484"
            size="16"
          />
          <van-icon v-else size="16" name="circle" color="#0D171A" />
          <span style="margin-left: 5px;color: #EED484;">{{ $t("全选") }}</span>
        </div>
        <button
          v-if="
            ['LEAD_INVITE_ADD_WECHAT', 'CHAT_GROUP_TASK'].includes(taskCode)
          "
          :disabled="!selection.length"
          @click="onSetTag"
        >
          批量标记
        </button>
        <van-button
          type="primary"
          size="small"
          :disabled="!selection.length"
          @click="onBind"
        >
          {{ config.btnText }}
        </van-button>
      </div>
    </div>
    <van-dialog
      v-model="show"
      confirmButtonColor="#b9921a"
      show-cancel-button
      :before-close="beforeClose"
    >
      <template #title>
        <van-cell :title="config.dialogTitle" required :border="false" />
      </template>
      <div class="tag-box">
        <div
          v-for="item in reasonList"
          :key="item.name"
          class="tag"
          :class="{ active: selectionReason.includes(item.name) }"
          @click="onSelectReason(item.name)"
        >
          {{ item.name }}
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import List from '@/components/baseList/list.vue'
import loading from '@/utils/loading'
import todoServices from '@/services/todoServices.js'
import { getHatchRuleDetail } from '@/services/clueIncubationServices.js'
import copyIcon from '@/images/copy.png'
import { copyText } from '@/utils/index'
import qwIcon from '@/components/card-view/qw-icon.vue'
let selectionMap = new Map()
export default {
  components: {
    List,
    qwIcon
  },
  props: {
    taskCode: {
      type: String,
      default: () => 'CHAT_GROUP_TASK',
    },
  },
  data() {
    return {
      copyIcon,
      totalCount: 0,
      changeTracker: 0,
      currentTotal: 0,
      index: 0,
      isShowCopyMobile: true,
      show: false,
      selectionReason: [],
    }
  },
  computed: {
    roleLogoName() {
      const obj = {}
      this.$store.getters.getDictHash(1014)?.forEach(({ code, name }) => {
        obj[code] = name
      })
      return obj
    },
    reasonList() {
      return this.$store.getters.getDictHash(this.config?.dictCode) || []
    },
    config() {
      return {
        CHAT_GROUP_TASK: {
          btnText: this.$t('批量建群'),
          invokeKey: 'openEnterpriseChat',
          taskName: this.$t('建群任务'),
          dictCode: '6001',
          dialogTitle: '无需建群原因',
        },
        LEAD_INVITE_ADD_WECHAT: {
          btnText: this.$t('批量短信邀请'),
          invokeKey: 'inviteBatch',
          taskName: this.$t('加微任务'),
          dictCode: '6000',
          dialogTitle: '无需加微原因',
        },
        CHAT_WELCOME_MSG: {
          btnText: this.$t('批量发送'),
          invokeKey: 'shareToExternalChat',
          taskName: this.$t('欢迎语任务'),
        },
        CHAT_ADD_MEMBER: {
          btnText: this.$t('批量添加'),
          invokeKey: 'updateEnterpriseChat',
          taskName: this.$t('添加群成员'),
        },
      }[this.taskCode]
    },
    selection() {
      this.changeTracker
      return Array.from(selectionMap.keys())
    },
  },
  watch: {
    taskCode: {
      handler() {
        this.onRefresh(false)
      },
    },
    immediate: true,
  },
  mounted() {
    this.getHatchRuleDetail()
  },
  methods: {
    async beforeClose(action, done) {
      if (action === 'confirm') {
        if (!this.selectionReason.length) {
          this.$toast('请选择原因')
          done(false)
          return
        }
        done()
        loading.showLoading()
        await todoServices.cancelTask({
          ids: this.selection,
          reasons: this.selectionReason.join(),
        })
        this.onRefresh()
      } else {
        done()
      }
    },
    onSelectReason(name) {
      if (this.selectionReason.includes(name)) {
        const index = this.selectionReason.findIndex((e) => e === name)
        this.selectionReason.splice(index, 1)
      } else {
        this.selectionReason.push(name)
      }
    },
    addC(phone) {
      this.copy(phone)
      window.wx.invoke('navigateToAddCustomer', {})
    },
    onSetTag() {
      this.selectionReason = []
      this.show = true
    },
    getHatchRuleDetail() {
      getHatchRuleDetail({}).then((res) => {
        const { phoneCopyFlag = true } = res || {}
        this.isShowCopyMobile = phoneCopyFlag
      })
    },
    copy(userMobile) {
      copyText(userMobile, this.$t('手机号复制成功'))
      // wx.setClipboardData({
      //   data: userMobile, // 设置的
      //   success: () => {
      //     this.$toast(this.$t('手机号复制成功'))
      //   },
      // })
    },
    async inviteBatch(data = []) {
      const ids = data.length ? data : this.selection
      await this.$dialog.confirm({
        title: '提示',
        message: this.$t('确定发送短信，邀请客户添加企业微信好友？'),
        confirmButtonColor: '#B9921A',
      })
      loading.showLoading()
      await todoServices.inviteBatch({ ids })
      loading.hideLoading()
      this.onRefresh()
    },
    onBind() {
      if (this.config.invokeKey && this[this.config.invokeKey]) {
        this[this.config.invokeKey]()
      }
    },
    // 群发消息
    shareToExternalChat() {
      const item = selectionMap.get(this.selection[this.index])
      const { chatId, welcomeMsg } = item.chatGroup
      wx.invoke(
        'shareToExternalChat',
        {
          chatIds: [chatId], // 客户群ID，从4.1.10版本开始支持（mac端不支持）
          text: {
            content: welcomeMsg, // 文本内容
          },
        },
        async (res) => {
          console.log(res, 'shareToExternalChat')
          if (res.err_msg == 'shareToExternalChat:ok') {
            await todoServices.updateBatchChatWelcomeMsgTask({
              ids: [item.id],
            })
            this.index++
            if (this.index < this.selection.length) {
              this.shareToExternalChat()
            } else {
              this.onRefresh()
            }
          } else {
            if (res.err_msg != 'shareToExternalChat:cancel') {
              this.$toast.fail(res.err_msg)
            }
            setTimeout(() => {
              this.onRefresh()
            }, 1500)
          }
        }
      )
    },
    // 全选
    onSelectAll() {
      if (this.currentTotal) {
        if (this.selection.length == this.currentTotal) {
          selectionMap.clear()
        } else {
          this.$refs.list.list.forEach((element) => {
            if (!selectionMap.has(element.id))
              selectionMap.set(element.id, element)
          })
        }
        this.changeTracker++
      }
    },
    // 单选
    onSelect(item) {
      if (selectionMap.has(item.id)) {
        selectionMap.delete(item.id)
      } else {
        selectionMap.set(item.id, item)
      }
      this.changeTracker++
    },
    // 添加群成员
    async updateEnterpriseChat() {
      const {
        id,
        chatMember: { chatId, userId },
      } = selectionMap.get(this.selection[this.index])
      // loading.showLoading()
      // const arr = await todoServices.openEnterpriseChatBeforeUpdate({
      //   ids: [id],
      // })
      // const { members = [], chatId } = arr[0] || {}
      // loading.hideLoading()
      wx.invoke(
        'updateEnterpriseChat',
        {
          chatId, // 通过企业微信创建群聊接口返回的chatId
          userIdsToAdd: userId, // 参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。目前仅能往群里添加企业内部成员；
        },
        async (res) => {
          console.log(res, 'res')
          if (res.err_msg == 'updateEnterpriseChat:ok') {
            await todoServices.chatNewMember({ ids: [id] })
            this.index++
            if (this.index < this.selection.length) {
              this.updateEnterpriseChat()
            } else {
              this.onRefresh()
            }
          } else {
            this.$toast(res.err_msg || res.errMsg)
          }
        }
      )
    },

    // 建群
    async openEnterpriseChat() {
      const { id } = selectionMap.get(this.selection[this.index])
      loading.showLoading()
      const arr = await todoServices.openEnterpriseChatBeforeUpdate({
        ids: [id],
      })
      const { chatName, members = [], externalUserId } = arr[0] || {}
      loading.hideLoading()
      wx.openEnterpriseChat({
        groupName: chatName, // 与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        userIds: members.map(({ userid }) => userid).join(';'), // 参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        externalUserIds: externalUserId, // 外部联系人id
        success: async ({ chatId }) => {
          await todoServices.chatGroupTaskUpdate([{ ...arr[0], chatId }])
          this.index++
          if (this.index < this.selection.length) {
            this.openEnterpriseChat()
          } else {
            this.onRefresh()
          }
        },
      })
    },
    onRefresh(e = true) {
      e && this.$emit('reLoadCount')
      this.$refs.list.onRefresh()
      selectionMap.clear()
      this.index = 0
      this.changeTracker++
    },
    // 获取列表数据
    fetchApi(obj = {}) {
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        taskStatus: 0,
        taskCode: this.taskCode,
      }
      loading.showLoading()
      return todoServices.qwTaskList(params).then((res) => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        this.currentTotal =
          obj.currentPage == 1
            ? res.dataList.length
            : this.currentTotal + res.dataList.length
        return res
      })
    },
    // 跳转线索列表选择线索进行关联
    goCluePage(data) {
      this.$router.push({
        path: '/clue-list',
        query:{
          fromWeChatTask: true,
          followUserIds: data.planExecutorId,
          customerName: data.customerName,
          customerWechatId: data.customerWechatId
        }
      })
    },
    // 创建新线索
    goCreateWechatCluePage(data) {
      this.$router.push({
        path: '/create-wechat-clue',
        query:{
          customerName: data.customerName,
          customerWechatId: data.customerWechatId
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.tag-box {
  display: flex;
  flex-wrap: wrap;
  padding-left: 16px;
  .tag {
    text-align: center;
    padding: 2px 8px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    line-height: 16px;
    font-size: 12px;
    min-width: 66px;
    flex-shrink: 0;
    margin-bottom: 16px;
    margin-right: 16px;
    &.active {
      color: #b9921a;
      background: rgba(238, 212, 132, 0.45);
      border-color: rgba(238, 212, 132, 0.45);
    }
  }
}
.item-actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid rgba(13, 23, 26, 0.05);
  // height: 32px;
  padding: 8px 0;
  span {
    color: #b9921a;
    cursor: pointer;
  }
}
.footer {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
  background: white;
  .action-box {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
.group-chats {
  .label {
    color: rgba(13, 23, 26, 0.45);
  }

  .mb-8 {
    margin-bottom: 8px;
  }
  .mb-16 {
    margin-bottom: 16px;
  }
  .total {
    font-size: 14px;
    font-family: HYQiHei, HYQiHei;
    font-weight: normal;
    color: #262626;
    line-height: 22px;
    padding: 9px 16px 0;
  }
  .select-icon {
    font-size: 0;
    padding: 4px;
    cursor: pointer;
  }
  .item-wrap {
    // display: flex;
    // flex-direction: column;
    // min-height: 100px;
    // justify-content: space-between;
    background: white;
    padding: 16px 12px 0 12px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-size: 13px;
    overflow: hidden;
    .user-info {
      display: flex;
      align-items: center;
      font-size: 14px;
      .taskName {
        margin-left: auto;
        font-size: 14px;
      }
    }
    .wechat-info {
      display: flex;
      align-items: center;
      .icon{
        margin-left: 16px;
      }
    }
    .actions {
      border-top: 1px solid rgba(13, 23, 26, 0.05);
      height: 44px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      :last-child {
        color: #b9921a;
        cursor: pointer;
      }
    }
  }
}
</style>
